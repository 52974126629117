import { useState } from "react";

function Form({ getData }) {
  const [weight, setweight] = useState("");
  const [height, setheight] = useState("");
  const [alert, setalert] = useState(false);
  const onSubmit = (e) => {
    e.preventDefault();
    if (isNaN(weight) || isNaN(height)) {
      console.log("Not a valid input");
      setalert(true);
    } else {
      getData(weight, height);
      setalert(false);
      //setheight("");
      //setweight("");
    }
  };
  //let alertMessage
  // if(alert){
  //   alertMessage=<div className="alert alert-danger" role="alert">Please Enter a valid datas</div>
  // }else{
  //   alertMessage=""
  // }

  return (
    <div className="col-sm-4 shadow bg-dark text-light rounded px-3">
      <h1 className="text-center pt-3 text-light h2">BMI Calculator</h1>
      <form onSubmit={onSubmit}>
        <div className="row">
          <div className="col col-sm-6">
            <div className="my-3">
              <label className="form-label"> Weight(kg) : </label>
              <input
                type="text"
                value={weight}
                onChange={(e) => setweight(e.target.value)}
                className="form-control" placeholder="eg : 100"
                required
              />
            </div>
          </div>
          <div className="col col-sm-6">
            <div className="my-3">
              <label className="form-label"> Height(m) : </label>
              <input
                type="text"
                value={height}
                onChange={(e) => setheight(e.target.value)}
                className="form-control" placeholder="eg: 1.9"
                required
              />
            </div>
          </div>
        </div>
        <input type="submit" className="btn btn-primary my-3" value="Get BMI" />
      </form>
      {alert && (
        <div className="alert alert-danger" role="alert">
          Please Enter a valid datas
        </div>
      )}
    </div>
  );
}

export default Form;
